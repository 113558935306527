import { MouseEvent } from 'react';
import {
  StyledButton,
  Container,
  FileLabel,
  HiddenInput,
} from './Button.styled';

import { useState } from 'react';

interface IButton {
  label: string;
  onClick?: (event: MouseEvent) => void;
  uploadOnChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  uploadButton?: boolean;
  icon?: JSX.Element;
  hoverIcon?: JSX.Element;
  multiple?: boolean;
  buttonLengthLimit?: number;
  type?: string;
  height?: string;
  width?: string;
  primary?: boolean;
  secondary?: boolean;
  table?: boolean;
  red?: boolean;
  redOutline?: boolean;
  link?: boolean;
  color?: string;
  rightIcon?: boolean;
  block?: boolean;
  pwId?: string;
  style?: any;
}

const Button = (props: IButton) => {
  const [hover, setHover] = useState(false);
  const handleFileClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };
  return (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.uploadButton ? (
        <>
          <FileLabel {...props} htmlFor="file-upload">
            {props.icon}
            <span>{props.label}</span>
          </FileLabel>
          <HiddenInput
            type="file"
            accept="*"
            multiple={props.multiple}
            id="file-upload"
            onClick={handleFileClick}
            onChange={props.uploadOnChange}
          />
        </>
      ) : (
        <StyledButton
          {...props}
          onClick={props.onClick}
          data-testid={props.pwId}
        >
          {props.hoverIcon && hover
            ? !props.rightIcon && props.hoverIcon
            : !props.rightIcon && props.icon}
          {props.label}
          {props.hoverIcon && hover
            ? props.rightIcon && props.hoverIcon
            : props.rightIcon && props.icon}
        </StyledButton>
      )}
    </Container>
  );
};

export default Button;
