import { css } from 'styled-components';

interface IBreakpointsDefinitions {
  [breakpoint: string]: string;
}

interface IBreakpoints {
  [breakpoint: string]: any;
}

export const breakpoints: IBreakpointsDefinitions = {
  desktop: '1920px',
  bigLaptop: '1440px',
  smallLaptop: '1345px',
  bigTablet: '1023px',
  midTablet: '990px',
  smallTablet: '767px',
  bigPhone: '640px',
  smallPhone: '460px',
};

export const respondTo = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (literals: TemplateStringsArray, ...args: any) => css`
      @media (max-width: ${breakpoints[label]}) {
        ${css(literals, ...args)};
      }
    `;
    return accumulator;
  },
  {} as IBreakpoints
);
