import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import {
  MODEL_CONFIGURATION_CONTAINER_HEIGHT,
  MODEL_CONFIGURATION_CONTAINER_HEIGHT_MOBILE,
} from './ModelConfiguration/ModelConfiguration.styled';

interface StyledProps {
  isExpanded: boolean;
  numberOfModelsInCategory: number;
  withoutLine: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${(props: StyledProps) =>
    !props.withoutLine &&
    css`
      border-bottom: 1rem solid ${COLORS.GRAY_100};
    `}
`;

export const CategoryNameLabel = styled.label`
  font-weight: 400;
  font-size: 18rem;
  line-height: 24.5rem;
  color: ${COLORS.BLACK};
`;

export const CategoryNameAndChevronWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 84rem;
  cursor: pointer;
  user-select: none;
`;

export const ChevronImg = styled.img`
  width: 30rem;
  height: 30rem;
  margin-bottom: 5rem;
  margin-left: 8rem;
  cursor: pointer;
  ${(props: StyledProps) =>
    props.isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ModelConfigurationsWrapper = styled.div<{ isExpanded: boolean }>`
  max-height: ${(props: StyledProps) => {
    const { isPhone, isBigLaptop } = useBreakpointFlag();
    const totalConfigurationsHeightInt =
      props.numberOfModelsInCategory *
      (isPhone || isBigLaptop
        ? MODEL_CONFIGURATION_CONTAINER_HEIGHT_MOBILE
        : MODEL_CONFIGURATION_CONTAINER_HEIGHT);
    return props.isExpanded
      ? css`
          ${totalConfigurationsHeightInt}rem
        `
      : '0';
  }};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  margin-left: ${marginSm};
  margin-right: ${marginSm};

  ${respondTo.bigLaptop`
    display: flex;
    flex-direction: column;
    gap: 20rem;
  `}
`;
