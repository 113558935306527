import { useMemo } from 'react';
import { breakpoints } from 'assets/styled/breakpoints';
import { useWindowDimensions } from './useWindowDimensions';

export const convertPxToNumber = (value: string): number => {
  return parseInt(value.replace('px', ''), 10);
};

export const useBreakpointFlag = () => {
  const { width } = useWindowDimensions();

  const breakpointValues = useMemo(
    () => ({
      isSmallPhone: width <= convertPxToNumber(breakpoints.smallPhone),
      isPhone: width <= convertPxToNumber(breakpoints.bigPhone),
      isSmallTablet: width <= convertPxToNumber(breakpoints.smallTablet),
      isMidTablet: width <= convertPxToNumber(breakpoints.midTablet),
      isBigTablet: width <= convertPxToNumber(breakpoints.bigTablet),
      isSmallLaptop: width <= convertPxToNumber(breakpoints.smallLaptop),
      isBigLaptop: width <= convertPxToNumber(breakpoints.bigLaptop),
      isDesktop: width > convertPxToNumber(breakpoints.bigLaptop),
      isWideScreen: width > convertPxToNumber(breakpoints.desktop),
    }),
    [width]
  );

  return breakpointValues;
};
